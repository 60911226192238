@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  color: #FFF;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.bottom-container {
    display: flex;
    flex-direction: row;
    width: 100%; /* Full width of the viewport */
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.29) 0%, rgba(0, 0, 0, 0.60) 100%);
    box-sizing: border-box;
  }

.bottom-text {
  color: #FFF;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.notify-button {
  display: flex;
  max-width: 200px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  border: 1px solid rgba(167, 167, 167, 0.11);
  background: rgba(255, 255, 255, 0.12);
  flex: 1 0 auto;
}

@media (max-width: 1200px) {
  .bottom-container {
    padding: 24px;
    gap: 20px;
    flex-direction: column;
  }

  .bottom-text {
    text-align: left;
  }
}

@media (max-width: 768px) {
  .logo, .bottom-text, .notify-button {
    font-size: 13px;
    line-height: 140%;
  }
  .bottom-container {
    width: 100%;
    padding: 16px;
    gap: 10px;
    flex-direction: column;
  }
  .notify-button {
    width: 100%;
  }
}
