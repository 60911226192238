@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  font-family: 'Inter', sans-serif;
}

.loading-percentage {
  font-size: 6em;
  font-weight: 300;
  color: #d6d6d6;
  margin: 0 0 20px 20px;
}